<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Estimate</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Estimate
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Estimate</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          v-if="checkSinglePermission('create_estimate')"
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$router.push({ name: 'new-estimate' })"
        >
          New Estimate
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @input="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="ml-1 filter-input mr-1 mg-t-5"
              @input="selectEndDate"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="filterParams.searched"
            class="float-right"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Estimate ID</th>
              <th class="wd-25p">
                Name
                <i
                  class="fa fa-info-circle primary"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  title="L - Lead, C - Customer"
                ></i>
              </th>
              <th class="wd-10p">Amount</th>
              <th class="wd-10p">Validity</th>
              <th
                class="wd-10p text-center"
                v-if="
                  checkIfAnyPermission([
                    'view_estimate',
                    'edit_estimate',
                    'cancel_estimate',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && dataSets.length > 0">
            <tr v-for="(estimate, index) in dataSets" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </th>
              <td>{{ estimate.date }}</td>
              <td>
                {{ estimate.estimate_code }}
                <span v-if="estimate.canceled_by" class="tx-danger"
                  >- Canceled</span
                >
              </td>
              <td>
                {{ estimate.estimateable.name }}
                <span
                  v-if="
                    estimate.estimateable_type ==
                    'App\\Modules\\Customer\\Entities\\Customer'
                  "
                >
                  - C
                </span>
                <span v-else> - L </span>
              </td>
              <td>{{ parseDigitForSlip(estimate.estimate_total) }}</td>
              <td>
                <span :class="{ 'tx-danger': estimate.validity < today }">{{
                  estimate.validity
                }}</span>
              </td>
              <td
                class="text-center"
                v-if="
                  checkIfAnyPermission([
                    'view_estimate',
                    'edit_estimate',
                    'cancel_estimate',
                  ])
                "
              >
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="View Slip"
                  v-if="checkSinglePermission('view_estimate')"
                >
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('estimateSlip', estimate.id)"
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  @click="edit(estimate.id)"
                  v-if="
                    !estimate.canceled_by &&
                    checkSinglePermission('edit_estimate')
                  "
                  title="Edit Estimate"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  @click="cancelEstimate(estimate.id)"
                  v-if="
                    !estimate.canceled_by &&
                    checkSinglePermission('cancel_estimate')
                  "
                  title="Cancel Estimate"
                >
                  <i class="fas fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataSets.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <EstimateSlip />
  </div>
</template>
<script>
import EstimateSlip from "./Components/EstimateSlip";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../shared/SearchDayMonthComponent";
import _ from "lodash";
import { mapGetters } from "vuex";
import Services from "./Services/Service";

export default {
  components: {
    EstimateSlip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
      filterParams: {
        pageIndex: 10,
        branch: "",
        sales_type: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "eventMessage",
      "today",
    ]),
    ...mapGetters("estimate", ["dataSets", "slipSettings"]),
  },
  beforeMount() {
    // get Data
    this.getFilteredDataWithoutDates();
    this.pageReload = true;

    Services.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("estimate/setSlipSettings", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    onChangePage(pageNo) {
      console.log(pageNo);
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.page = 1;
        this.pageSerialNo = 1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
      }
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.$store.commit("estimate/setItemSearchLoading", true);
      this.getFilteredDataWithoutDates();
    }, 500),

    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getFilteredEstimates(params)
        .then((response) => {
          let value = response.data.data;
          this.$store.commit("estimate/setDataSets", value.data);
          this.totalPageCount = value.total_no_of_pages;
          this.loading = false;
          this.$store.commit("estimate/setItemSearchLoading", false);
        })
        .catch((error) => {
          this.$store.commit("estimate/setDataSets", []);
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("estimate/setItemSearchLoading", true);
        });
    },
    cancelEstimate(id) {
      this.$swal({
        title: "Do you really want to cancel this Estimate ?",
        html: `<label>Remarks: </label>
        <textarea id="textarea" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("textarea").value;
          // cancel the sales with remarks
          return Services.cancelEstimate(id, { remarks: remarks })
            .then((response) => {
              this.dataSets.forEach((item, index) => {
                if (item.id == response.data.data.id) {
                  this.$set(this.dataSets, index, response.data.data);
                }
              });
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              return response;
            })
            .catch((error) => {
              console.log(error);
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //poping up the transferSlip modalif
          // this.$store.commit("setDataId", result.value.data.id);
          // this.$store.dispatch("modalWithMode", {
          //   id: "transferSlip",
          //   mode: "read",
          // });
        }
      });
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(id) {
      this.$router.push({ name: "edit-estimate", params: { id: id } });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/lead-estimate/${id}`);
    },
  },
};
</script>
<style scoped>
</style>